import React, { useEffect, useState } from 'react';
import api from '../api';
import { spleisUrl } from '../config';
import { showError } from '../store';
import ShowMore from '../ShowMore/ShowMore';
import { Helmet } from 'react-helmet';
import TaskContainer from '../TaskContainer/TaskContainer';
import './VippsPartner.scss';
import SalesUnitsForm from './SalesUnitsForm';

const ProductOrders = ({ orders, limit }) => {
    if (!orders || orders.length === 0) {
        return null;
    }
    return (
        <div>
            <ShowMore
                items={orders}
                initialShowCount={limit}
                listCreator={(showMoreItems) => {
                    return (
                        <table className={'table table-striped'}>
                            <thead>
                                <tr>
                                    <th>OrderId</th>
                                    <th>Status</th>
                                    <th>Organisasjon</th>
                                    <th>URL</th>
                                </tr>
                            </thead>
                            <tbody>
                                {showMoreItems.map((t) => {
                                    return (
                                        <tr key={t.productOrderId}>
                                            <td>{t.productOrderId}</td>
                                            <td>{t.status}</td>
                                            <td>
                                                <div>{t.organizationName}</div>
                                                <div>{t.orgNumber}</div>
                                            </td>
                                            <td>
                                                <a href={t.productOrderUrl}>{t.productOrderUrl}</a>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    );
                }}
            />
        </div>
    );
};

const VippsPartner = () => {
    const [productOrders, setProductOrders] = useState([]);
    const [expiredProductOrders, setExpiredProductOrders] = useState([]);

    useEffect(() => {
        api.get(`${spleisUrl}/api/admin/vipps/partner/product-orders`)
            .then((res) => {
                setExpiredProductOrders(res.filter((r) => r.status === 'EXPIRED'));
                setProductOrders(res.filter((r) => r.status !== 'EXPIRED'));
            })
            .catch(() => showError('Feil ved henting av product orders'));
    }, []);

    return (
        <TaskContainer>
            <Helmet>
                <title>Vipps Product Orders</title>
            </Helmet>
            <header className="task-header">
                <h1 className="task-title">Vipps Product Orders</h1>
            </header>
            <div className={'productOrderContainer'}>
                <SalesUnitsForm />
                <div>
                    <h2>Vipps statuser</h2>
                    <table className={'table table-striped'}>
                        <tr>
                            <th>Status</th>
                            <th>Beskrivelse</th>
                        </tr>
                        <tr>
                            <td>RECEIVED</td>
                            <td>
                                The prefilled PO exists (it has been sent to us), but nothing has been done with it yet.
                            </td>
                        </tr>
                        <tr>
                            <td>MERCHANT_VIEWED</td>
                            <td> The PO URL has been accessed at least once.</td>
                        </tr>
                        <tr>
                            <td>ORDER_SUBMITTED</td>
                            <td>
                                The merchant has submitted the PO. If the PO is rejected, the state will still be
                                ORDER_SUBMITTED.
                            </td>
                        </tr>
                        <tr>
                            <td>EXPIRED</td>
                            <td>The PO has expired, which happens after 14 days.</td>
                        </tr>
                        <tr>
                            <td>COMPLETED</td>
                            <td>
                                The PO was successfully completed. The accompanying sales unit information will also be
                                returned at this point.
                            </td>
                        </tr>
                    </table>
                </div>
                <p className={'partnerUrl'}>
                    Url for registrering av en ny product order:{' '}
                    <a href="https://portal.vipps.no/register?redirectTo=%2Fregister%2Ftemplate%2F2af3d706-cb58-4f43-a44d-0c7f13e71316">
                        portal.vipps.no
                    </a>
                </p>
                <h2>Bestillinger</h2>
                <ProductOrders orders={productOrders} limit={10} />
                <h2>Utløpte bestillinger</h2>
                <ProductOrders orders={expiredProductOrders} limit={2} />
            </div>
        </TaskContainer>
    );
};

export default VippsPartner;
